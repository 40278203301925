// 实施管理  implementationManage
// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const httpServer = {
    // 实施管理 列表各状态计数
    implementationManageCount(params) {
        return get("/task/task/countByStage", params);
    },
    // 归档计划 列表各状态计数
    archivingPlanCount(params) {
        return get("/task/task/countByStatus", params);
    },
    // 列表查询
    getList(params) {
        return postJson("/task/task/table", params);
    },
    // 查询施工方案阶段详情
    getDetailsPlanInfo(params) {
        return get("/task/task/findPlanInfo", params);
    },
    // 查询部署实施阶段详情
    getDetailsSetup(params) {
        return get("/task/task/findDeployInfo", params);
    },
    // 查询详情已完成节点
    getNodeStatus(params) {
        return get("/task/node/findNodeByTaskId", params);
    },
    // 保存施工计划详情
    updateTask(params) {
        return postJson("/task/task/edit", params);
    },
    // 完成施工环节
    overTask(params) {
        return postJson("/task/task/finishStage", params);
    },
    // 跳过施工环节
    skipTask(params) {
        return postJson("/task/task/jumpStage", params);
    },
    // 施工日志 / 新增或编辑日志
    saveSetupLog(params) {
        return postJson("/task/construction-log/save", params);
    },
    // 施工日志 / 删除日志
    delSetupLog(id) {
        return get(`/task/construction-log/delete/${id}`);
    },
};

export default httpServer;
