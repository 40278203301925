// 实施管理
<template>

    <div class="implementationManage baseBg">

        <div class="listBox mainWhiteBg">
            <el-tabs v-model="activeName"
                     ref="searchTab"
                     class="searchTab">
                <template v-if="commonJs.isShowBtn(detailRole, 'Tab_进行中全部')">
                    <el-tab-pane name="进行中全部">
                        <div class="tabsLabel"
                             @click="commonJs.setTabId(detailRole,'Tab_进行中全部')"
                             slot="label">
                            <el-badge :type="activeName == '进行中全部' ? 'primary' : 'warning'"
                                      :value="counts.inProgressNumber"
                                      class="item">
                                进行中全部
                            </el-badge>
                        </div>
                        <!-- 实施管理列表 -->
                        <com-implementation-manage v-if="activeName === '进行中全部'"
                                                   className="tab1"
                                                   :key="activeName"
                                                   @update="update"
                                                   :activeName="activeName"></com-implementation-manage>
                    </el-tab-pane>
                </template>

                <template v-if="commonJs.isShowBtn(detailRole, 'Tab_施工方案')">
                    <el-tab-pane name="施工方案">
                        <div class="tabsLabel"
                             @click="commonJs.setTabId(detailRole,'Tab_施工方案')"
                             slot="label">
                            <el-badge :type="activeName == '施工方案' ? 'primary' : 'warning'"
                                      :value="counts.planNumber"
                                      class="item">
                                施工方案
                            </el-badge>
                        </div>
                        <!-- 实施管理列表 -->
                        <com-implementation-manage v-if="activeName === '施工方案'"
                                                   className="tab2"
                                                   :key="activeName"
                                                   @update="update"
                                                   :activeName="activeName"></com-implementation-manage>
                    </el-tab-pane>
                </template>

                <template v-if="commonJs.isShowBtn(detailRole, 'Tab_开工准备')">
                    <el-tab-pane name="开工准备">
                        <div class="tabsLabel"
                             @click="commonJs.setTabId(detailRole,'Tab_开工准备')"
                             slot="label">
                            <el-badge :type="activeName == '开工准备' ? 'primary' : 'warning'"
                                      :value="counts.readyNumber"
                                      class="item">
                                开工准备
                            </el-badge>
                        </div>
                        <!-- 实施管理列表 -->
                        <com-implementation-manage v-if="activeName === '开工准备'"
                                                   className="tab3"
                                                   :key="activeName"
                                                   @update="update"
                                                   :activeName="activeName"></com-implementation-manage>
                    </el-tab-pane>
                </template>

                <template v-if="commonJs.isShowBtn(detailRole, 'Tab_部署实施')">
                    <el-tab-pane name="部署实施">
                        <div class="tabsLabel"
                             @click="commonJs.setTabId(detailRole,'Tab_部署实施')"
                             slot="label">
                            <el-badge :type="activeName == '部署实施' ? 'primary' : 'warning'"
                                      :value="counts.deployNumber"
                                      class="item">
                                部署实施
                            </el-badge>
                        </div>
                        <!-- 实施管理列表 -->
                        <com-implementation-manage v-if="activeName === '部署实施'"
                                                   className="tab4"
                                                   :key="activeName"
                                                   @update="update"
                                                   :activeName="activeName"></com-implementation-manage>
                    </el-tab-pane>
                </template>

                <template v-if="commonJs.isShowBtn(detailRole, 'Tab_培训验收')">
                    <el-tab-pane name="培训验收">
                        <div class="tabsLabel"
                             @click="commonJs.setTabId(detailRole,'Tab_培训验收')"
                             slot="label">
                            <el-badge :type="activeName == '培训验收' ? 'primary' : 'warning'"
                                      :value="counts.checkNumber"
                                      class="item">
                                培训验收
                            </el-badge>
                        </div>
                        <!-- 实施管理列表 -->
                        <com-implementation-manage v-if="activeName === '培训验收'"
                                                   className="tab5"
                                                   :key="activeName"
                                                   @update="update"
                                                   :activeName="activeName"></com-implementation-manage>
                    </el-tab-pane>
                </template>

            </el-tabs>

        </div>

    </div>

</template>

<script>
import ComImplementationManage from "./components/comImplementationManage";
import { Loading } from "element-ui";
import API from "@/api/implementationManage.js";
export default {
    name: "implementationManage",

    props: [],

    components: {
        ComImplementationManage,
    },

    data() {
        return {
            activeName: "进行中全部",
            counts: {}, // 列表数量
            detailRole: null, // 权限数据
        };
    },

    created() {
        this.commonJs.getFistTabId(this.detailRole, [
            "Tab_进行中全部",
            "Tab_施工方案",
            "Tab_开工准备",
            "Tab_部署实施",
            "Tab_培训验收",
        ]);
    },

    /* 组件缓存触发 */
    activated() {
        this.commonJs.getFistTabId(this.detailRole, [
            "Tab_进行中全部",
            "Tab_施工方案",
            "Tab_开工准备",
            "Tab_部署实施",
            "Tab_培训验收",
        ]);
        // 实施管理 列表各状态计数
        this.implementationManageCount();
    },

    mounted() {
        // 实施管理 列表各状态计数
        this.implementationManageCount();
    },

    methods: {
        // 实施管理 列表各状态计数
        implementationManageCount() {
            let loading = Loading.service({
                target: document.querySelector(".el-badge"),
            });
            API.implementationManageCount()
                .then((res) => {
                    loading.close();
                    this.counts = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },

        // 更新计数
        update(ref, data) {
            let obj = {
                培训验收: "checkNumber",
                部署实施: "deployNumber",
                开工准备: "readyNumber",
                施工方案: "planNumber",
                进行中全部: "inProgressNumber",
            };
            this.counts[obj[ref]] = data.totalCount;
        },
    },

    computed: {
        // 权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 225 });
        },
    },

    watch: {
        //角色按钮权限
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "实施管理_列表"
                );
                if (
                    this.commonJs.isShowBtn(this.detailRole, "Tab_进行中全部")
                ) {
                    this.activeName = "进行中全部";
                } else if (
                    this.commonJs.isShowBtn(this.detailRole, "Tab_施工方案")
                ) {
                    this.activeName = "施工方案";
                } else if (
                    this.commonJs.isShowBtn(this.detailRole, "Tab_开工准备")
                ) {
                    this.activeName = "开工准备";
                } else if (
                    this.commonJs.isShowBtn(this.detailRole, "Tab_部署实施")
                ) {
                    this.activeName = "部署实施";
                } else if (
                    this.commonJs.isShowBtn(this.detailRole, "Tab_培训验收")
                ) {
                    this.activeName = "培训验收";
                } else {
                    this.activeName = "";
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.implementationManage ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.implementationManage ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}
</style>
